import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Plantilla from "../../components/plantillas"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Seo from "../../components/seo"
import Accesibilidad from "../../components/accesibilidad"

const Detalleplantillas = props => {
  const query = useStaticQuery(graphql`
    query Detalleplantilla {
      allStrapiPages {
        edges {
          node {
            content
            site
            slug
          }
        }
      }
    }
  `)
  const projects = query.allStrapiPages.edges

  return (
    <>
      {projects.map(({ node: project }, index) => {
        if (project.slug === "plantilla") {
          return (
            <div key={`eg-${index}`}>
              <Accesibilidad />
              <Header site="personas" />
              <Seo title="Personas" />

              <div
                className="mitexto_color"
                layout="fullWidth"
                dangerouslySetInnerHTML={{
                  __html: project.content,
                }}
              />
              <br></br>
              <br></br>
              <br></br>

              <Plantilla></Plantilla>

              <Footer />
            </div>
          )
        }
      })}
    </>
  )
}

Detalleplantillas.propTypes = {
  siteTitle: PropTypes.string,
}

Detalleplantillas.defaultProps = {
  siteTitle: ``,
}

export default Detalleplantillas
