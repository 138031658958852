import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

const Plantilla = ({ children, site }) => {
  const query = useStaticQuery(graphql`
    query Plantillas {
      allStrapiPlantillas(sort: { order: ASC, fields: id }) {
        edges {
          node {
            id
            Titulo
            Descripcion
            Contenido
            Tipo
          }
        }
      }
    }
  `)

  let plantillas = query.allStrapiPlantillas.edges

  return (
    <div className="mitexto margen_plantilla">
      <br />
      <div className="w-10/12 mx-auto pb-16 text-center">
        <div className="gap-8 mx-auto inline-grid grid-cols-1 text-center alineacion_total_servicio_en_linea mitexto">
          {plantillas.map(({ node: plantilla }, index) => {
            return (
              <div key={`e-${index}`}>
                <button className="accordion">
                  <strong className="green-primary text-poppins-bold">
                    {plantilla.Titulo}
                  </strong>
                </button>
                <div className="panel mitexto">
                  <div className="flex flex-col justify-center lg:inline-grid grid-cols-2 gap-8 pa-5">
                    <div>
                      <br />
                      <button
                        className="rounded-full green-secundary"
                        onClick={() => {
                          navigator.clipboard.writeText(plantilla.Contenido)
                          alert("Texto copiado.")
                        }}
                      >
                        Copiar elemento
                      </button>
                      <br />
                      <pre
                        style={{ height: "300px", overflowY: "auto" }}
                        className="language-html line-numbers no-whitespace-normalization"
                      >
                        <code className="language-html line-numbers no-whitespace-normalization">
                          {plantilla.Contenido}
                        </code>
                      </pre>
                    </div>
                    <div
                      style={{
                        height: "300px",
                        overflowY: "auto",
                        padding: "2rem",
                      }}
                    >
                      <strong className="title mitexto_color green-secundary text-poppins-bold">
                        Vista
                        <span className="mitexto_color color-texto-parrafo">
                          {" "}
                          previa
                        </span>
                      </strong>
                      <br />
                      <br />
                      <div
                        className="relative"
                        dangerouslySetInnerHTML={{
                          __html: plantilla.Contenido,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

Plantilla.propTypes = {
  siteTitle: PropTypes.string,
}

Plantilla.defaultProps = {
  siteTitle: ``,
}

export default Plantilla
